import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'context/IntlContext';
import { Text, Box, Grid } from 'components';
import ProductsTableSection from './ProductsTableSection';
import FundsProtectionSection from './FundsProtectionSection';
import Section from '../../../../../components/Section';
import { CalculatorChart } from './Calculator';
import DirectSignup from '../../../../../components/DirectSignup';
import Seo from '../../../../../components/SEO';
import Motto from '../../../../../components/Motto';




const DisclaimerBottom = styled.p`
  box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: block;
    color: #233143;
    font-size: 12px;
    line-height: 24px;
    margin: 16px 0 44px 0;
`;

const SmartInvestments = () => {
  const { t, locale } = useIntl();
  const [href, setHref] = useState<string>('');
  const isSpanishPage = locale === 'es';

  useEffect(() => {
    let isMounted = true;

    if (!t || !isMounted) return;

    setHref(t('amazon.banner.link') as string);

    return () => {
      isMounted = false;
    };
  }, [t]);

  return (
    <>
      <Seo title={t('global.meta.title')} description={''} />
      <Box
        as="section"
        sx={{
          bg: 'primaryBackground',
          pb: [11, 11, 11, 0],
          textAlign: 'initial',
        }}
      >
        <Section innerSX={{ pt: [32, 32, 32, 40] }}>
          {/* {isSpanishPage && <AmazonBanner href={href} />} */}
          <ProductsTableSection />
        </Section>
        <Section
          outterSX={{
            bg: 'secondaryBackground',
          }}
        >
          <CalculatorChart deposit={10000} timeMaxYears={15} />
        </Section>
        <Section>
          <FundsProtectionSection />
        </Section>
        <Section>
          <Text mb={5} variant="sectionTitle">
            {t('homepage.marketplace.faq_section.title')}
          </Text>
          <Grid gap={9} columns={[1, '1fr', '1fr 1fr']}>
            <Motto
              title={t('homepage.marketplace.faq_section.interest.title')}
              description={t(
                'homepage.marketplace.faq_section.interest.subtitle',
              )}
            />
            <Motto
              title={t('homepage.marketplace.faq_section.cancel.title')}
              description={t(
                'homepage.marketplace.faq_section.cancel.subtitle',
              )}
            />
            <Motto
              title={t('homepage.marketplace.faq_section.protection.title')}
              description={t(
                'homepage.marketplace.faq_section.protection.subtitle',
              )}
            />
            <Motto
              title={t('homepage.marketplace.faq_section.withdraw.title')}
              description={t(
                'homepage.marketplace.faq_section.withdraw.subtitle',
              )}
            />
          </Grid>
        </Section>
        <Section noDivider>
          <Text mb={5} variant="sectionTitle" sx={{ textAlign: 'center' }}>
            {t('homepage.account.reinforcing_statement.title')}
          </Text>
          <Text color="quaternary" sx={{ textAlign: 'center', pb: 8 }}>
            {t('missedkey')}
          </Text>
          <DirectSignup
            variant="dark"
            color="#293040"
            sx={{
              textAlign: 'center',
              m: '0 auto',
              maxWidth: ['100%', '100%', '75%', '50%'],
            }}
          />
         <DisclaimerBottom> {t('smart.products.legal.disclaimer')} </DisclaimerBottom>
        </Section>
      </Box>
    </>
  );
};

export default SmartInvestments;

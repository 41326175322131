import React from 'react';
import {
  Flex,
  Box,
  Text,
  Heading,
  Container,
} from 'components';
import { useIntl } from 'context/IntlContext';
import Section from '../../../components/Section';

const MarketplaceTitle = () => {
  const { t } = useIntl();

  return (
    <Section
      noDivider
      innerSX={{
        pb: '40px',
      }}
    >
      <Flex sx={{ justifyContent: 'center' }}>
        <Box
          sx={{
            textAlign: ['center'],
          }}
        >
        </Box>
      </Flex>
    </Section>
  );
};

export default MarketplaceTitle;

import React from 'react';
import CashConversionCycle from './CashConversionCycle';
import Donations from './Donations';
import Mortgages from './Mortgages';
import SmartInvestments from './SmartInvestments';

const TabContent = ({ content }: { content: string }) => {
  const components: {
    [key: string]: () => JSX.Element;
  } = {
    investments: SmartInvestments,
    mortgages: Mortgages,
    donations: Donations,
    cash_conversion_cycle: CashConversionCycle,
  };

  if (components[content]) {
    const Content = components[content];
    return <Content />;
  }

  //TODO: Handle Error
  return <div>error</div>;
};

export default TabContent;
